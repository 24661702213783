import Link from "next/link";
import MegaNavLinks from "./MegaNavLinks";

const megaNavLinkData = {
  label: "Resources",
  sublinks: [
    {
      label: "Library",
      link: "/resources",
    },
    {
      label: "Families",
      link: "/family",
    },
    {
      label: "Law Enforcement",
      link: "/law-enforcement",
    },
    {
      label: "Content Creators",
      link: "/content-creators",
    },
    {
      label: "Universities",
      link: "/universities",
    },
    {
      label: "Sponsors",
      link: "/sponsorship",
    },
    {
      label: "The Citizen Detective",
      link: "/the-citizen-detective",
    },
  ],
};
export default function NavLinks() {

  return (
    <div className="bg-white flex flex-col lg:flex-row lg:items-center xl:pl-20 relative">
      <Link href="/cases">
        <a className="border-b border-gray-100 border-solid font-semibold p-4 text-sm lg:border-0">
          Cold Cases
        </a>
      </Link>
      <MegaNavLinks data={megaNavLinkData} />
      <a
        className="border-b border-gray-100 border-solid font-semibold p-4 text-sm lg:border-0"
        href="https://community.uncovered.com/"
      >
        Community
      </a>
      <a
        className="border-b border-gray-100 border-solid font-semibold p-4 text-sm lg:border-0"
        href="https://shop.uncovered.com/collections/all"
      >
        Shop
      </a>
      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
      <a
        className="border-b border-gray-100 border-solid font-semibold p-4 text-sm lg:border-0"
        href="/about"
      >
        About
      </a>
    </div>
  );
}
